import acruData from '../data/acru.json';
import landenData from '../data/landen.json';

export type Standplaatsen = {
    readonly [key: string]: Standplaats;
}

export type Standplaats = {
    readonly naam: string;
    readonly land: string;
    readonly basisIndeling: number;
    readonly correctieOpbasisIndeling: number;
    readonly zoneIndeling: number;
    readonly categorieIndeling: string;
    readonly kkc?: number;
    readonly posten: readonly string[];
}

type Standplaatsvergoeding = {
    readonly [key: string]: number
}

type VergoedingHuispersoneel = {
    readonly [key: string]: {
        readonly bedrag: number;
        readonly aanvullingPerKind: number;
    }
}

type VergoedingPassieveRepresentatie = {
    readonly [key: string]: {
        readonly [key: string]: number;
    }
}
type Transportvergoeding = {
    readonly [key: string]: {
        readonly [key: string]: number;
    }
}

type KoopkrachtcorrectieStandplaatsvergoeding = {
    readonly [key: string]: {
        readonly u: number;
        readonly partner: number;
        readonly kind: number;
    }
}

type Acru = {
    readonly standplaatsen: Standplaatsen;
    readonly inhoudingHuisvesting: number;
    readonly inhoudingBijkomendeKostenHuisvesting: number;
    readonly koopkrachtcorrectieNettoSalaris: number;
    readonly standplaatsvergoeding: Standplaatsvergoeding;
    readonly vergoedingHuispersoneel: VergoedingHuispersoneel;
    readonly vergoedingPassieveRepresentatie: VergoedingPassieveRepresentatie;
    readonly transportvergoeding: Transportvergoeding;
    readonly vergoedingKostenDubbeleHuishouding: number;
    readonly vergoedingKostenDubbeleHuishoudingAchterblijvenVooruitreizen: number;
    readonly koopkrachtcorrectieVergoedignKostenDubbeleHuishouding: number;
    readonly koopkrachtcorrectieStandplaatsvergoeding: KoopkrachtcorrectieStandplaatsvergoeding
    readonly vergoedingInternaatkosten: number;
    readonly vergoedingVerblijfGastgezin: number;
    readonly vergoedingZelfstandigeHuisvesting: number;
    readonly oudedagsvoorzieningPartner: number;
}


type Landen = {
    readonly [key: string]: {
        readonly naam: string;
        readonly partner?: {
            readonly werk?: string;
            readonly huwelijkMV?: string,
            readonly huwelijkMMVV?: string,
            readonly geregistreerdPartnerschapMV?: string,
            readonly geregistreerdPartnerschapMMVV?: string,
            readonly samenlevingsovereenkomstMV?: string,
            readonly samenlevingsovereenkomstMMVV?: string
        };
    }
}

export const acru: Acru = acruData;
export const landen: Landen = landenData;

export type Configuratie = {
    readonly kkc: number;
    readonly categorie: string;
    readonly zone: number;
    readonly schaal: number;
    readonly tandem?: boolean;
    readonly partner?: boolean;
    readonly kinderen?: readonly number[];
    readonly CdP?: boolean;
    readonly ingerichteDienstwoning?: boolean;
}

export type Situatie = {
    readonly id: string;
    readonly partner: boolean;
    readonly gescheidenHuishouding: boolean;
    readonly kinderen0tm11: number;
    readonly kinderen12plus: number;
    readonly standplaats: Standplaats;
    readonly schaal: number;
    readonly trede: number;
    readonly CdP: boolean;
    readonly ingerichteDienstwoning: boolean;
    readonly dienstauto: boolean;
}

export const vergoedingHuispersoneel = (situatie: Situatie) => {
    if (situatie.schaal >= 16 && situatie.CdP && situatie.ingerichteDienstwoning) return 0;
    const bedrag = acru.vergoedingHuispersoneel[situatie.schaal].bedrag;
    const aanvullingKinderen = Math.min(situatie.kinderen0tm11 + situatie.kinderen12plus || 0, 3) * acru.vergoedingHuispersoneel[situatie.schaal].aanvullingPerKind;
    return bedrag + aanvullingKinderen;
};

export const vergoedingPassieveRepresentatie = (situatie: Situatie) => {
    const kkc = situatie.standplaats.kkc || 1;
    const type = situatie.CdP
        ? situatie.ingerichteDienstwoning
            ? 'CdPDienstwoningIngericht'
            : 'CdPDienstwoningNietIngericht'
        : 'overigen'
    const basis = acru.vergoedingPassieveRepresentatie[type][situatie.schaal];
    const gecorrigeerd = basis * (0.75 + kkc * 0.25)
    return Math.round(gecorrigeerd * 100) / 100;
}

export const vergoedingPassieveRepresentatiePartner = (situatie: Situatie) => {
    if (!situatie.partner || situatie.gescheidenHuishouding) return 0;
    const kkc = situatie.standplaats.kkc || 1;
    const type = situatie.CdP
        ? situatie.ingerichteDienstwoning
            ? 'CdPDienstwoningIngericht'
            : 'CdPDienstwoningNietIngericht'
        : 'overigen'
    const basis = acru.vergoedingPassieveRepresentatie[type][situatie.schaal];
    const gecorrigeerd = basis * 0.35 * (0.60 + kkc * 0.40)
    return Math.round(gecorrigeerd * 100) / 100;
}

export const transportvergoeding = (situatie: Situatie) => {
    if (situatie.dienstauto) return 0
    return situatie.CdP
        ? acru.transportvergoeding.CdP[situatie.schaal]
        : acru.transportvergoeding.overigen[situatie.schaal]
}

export const standplaatsvergoeding = (situatie: Situatie) => {
    const kkc = situatie.standplaats.kkc || 1;
    const x = acru.standplaatsvergoeding[situatie.schaal];
    const y = situatie.standplaats.zoneIndeling;
    const basis = x + (y - 1) * 0.175 * x;
    const kkcDeel = acru.koopkrachtcorrectieStandplaatsvergoeding[situatie.standplaats.categorieIndeling].u
    const gecorrigeerd = basis * (kkcDeel * kkc + 1 - kkcDeel)
    return Math.round(gecorrigeerd * 100) / 100;
}

export const standplaatsvergoedingPartner = (situatie: Situatie) => {
    const kkc = situatie.standplaats.kkc || 1;
    if (!situatie.partner || situatie.gescheidenHuishouding) return 0
    const x = acru.standplaatsvergoeding[situatie.schaal];
    const y = situatie.standplaats.zoneIndeling;
    const basis = (x + (y - 1) * 0.175 * x) * 0.7;
    const kkcDeel = acru.koopkrachtcorrectieStandplaatsvergoeding[situatie.standplaats.categorieIndeling].partner
    const gecorrigeerd = basis * (kkcDeel * kkc + 1 - kkcDeel)
    return Math.round(gecorrigeerd * 100) / 100;
}

export const standplaatsvergoedingKinddelen = (situatie: Situatie) => {
    const kkc = situatie.standplaats.kkc || 1;
    const percentage = situatie.kinderen0tm11 * 0.125 + situatie.kinderen12plus * 0.25;
    const x = acru.standplaatsvergoeding["11"];
    const y = situatie.standplaats.zoneIndeling;
    const basis = (x + (y - 1) * 0.175 * x);
    const kkcDeel = acru.koopkrachtcorrectieStandplaatsvergoeding[situatie.standplaats.categorieIndeling].kind
    const gecorrigeerd = basis * percentage * (kkcDeel * kkc + 1 - kkcDeel)
    return Math.round(gecorrigeerd * 100) / 100;
}

export const oudedagsvoorzieningPartner = (situatie: Situatie) => {
    return (situatie.partner && !situatie.gescheidenHuishouding) ? acru.oudedagsvoorzieningPartner : 0
}

export const inhoudingHuisvesting = (situatie: Situatie, netto: number) => {
    if (situatie.gescheidenHuishouding) return 0;
    return Math.round(netto * acru.inhoudingHuisvesting * 100) / 100 * -1;
}

export const inhoudingBijkomendeKostenHuisvesting = (situatie: Situatie, netto: number) => {
    if (situatie.gescheidenHuishouding) return 0;
    return Math.round(netto * acru.inhoudingBijkomendeKostenHuisvesting * 100) / 100 * -1;
}

export const koopkrachtcorrectieNettoSalaris = (situatie: Situatie, netto: number) => {
    return Math.round(netto * acru.koopkrachtcorrectieNettoSalaris * ((situatie.standplaats.kkc || 1) - 1) * 100) / 100;
}