import abp from '../data/abp.json';
import loonbelastingData from '../data/loonbelasting.json'
import salaristabelData from '../data/salaristabel.json'

type Loonbelasting = {
    readonly [key: string]: number
}
type Salaristabel = {
    readonly [key: string]: {
        readonly [key: string]: number
    }
}

export const loonbelasting: Loonbelasting = loonbelastingData;
export const salaristabel: Salaristabel = salaristabelData;

export const premieABPPensioenNP = (salaris: number) => {
    const jaarinkomenPensioen = salaris * 12 * 1.1637; // Vakantiegeld, IKB en telewerkvergoeding
    const pensioengrondslag = jaarinkomenPensioen - abp.ABPKeuzepensioenOPNP.franchise;
    const pensioenpremie = (pensioengrondslag * abp.ABPKeuzepensioenOPNP.premieDeelnemer) / 12;
    return Math.round(pensioenpremie * 100) / 100 * -1;
}

export const premieABPPensioenAP = (salaris: number) => {
    const jaarinkomenPensioen = salaris * 12 * 1.1637; // Vakantiegeld, IKB en telewerkvergoeding
    const pensioengrondslag = jaarinkomenPensioen - abp.ABPArbeidsOngeschiktheidsPensioen.franchise;
    const pensioenpremie = (pensioengrondslag * abp.ABPArbeidsOngeschiktheidsPensioen.premieDeelnemer) / 12;
    return Math.round(pensioenpremie * 100) / 100 * -1;
}

export const loonheffing = (brutoloon: number) => {
    const tabelloonIndex = Object.keys(loonbelasting).findIndex(function (val) {
        return Number.parseFloat(val) > brutoloon;
    });
    const tabelloon = Object.keys(loonbelasting)[tabelloonIndex - 1] // Pick last item before treshold
    const maxBruto = Number.parseFloat(Object.keys(loonbelasting)[Object.keys(loonbelasting).length - 1])
    if (brutoloon > maxBruto) {
        // Voor hogere tabellonen: neem 49,50% van het verschil tussen dit hogere loon en € 9.630,00. Rond het resultaat af op centen in het voordeelv an de werknemer.
        // Tel dit bedrag op bij het bedrag aan inhouding dat hoort bij het tabelloon van € 9.630,00 in de kolom die van toepassing is op de werknemer.
        const aanvullendeLoonheffing = Math.floor(((brutoloon - maxBruto) * 0.495) * 100) / 100
        const hoogsteLoonheffing = Object.values(loonbelasting)[Object.values(loonbelasting).length - 1];
        return (hoogsteLoonheffing + aanvullendeLoonheffing) * -1;
    } else {
        return loonbelasting[tabelloon] * -1
    }
}

export const ikb = (brutoloon: number) => {
    const ikb = brutoloon * 0.1637;
    return Math.round(ikb * 100) / 100;
}