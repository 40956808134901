import { Button, Header, Table } from "semantic-ui-react";

import { Indicatie, sumIndicatie } from "./Indicatie";

const formatter = new Intl.NumberFormat("nl-NL", {
  style: "currency",
  currency: "EUR",
});

const TableExampleCollapsing = (props: { readonly indicatie: Indicatie }) => (
  <div className="Sidebar-table">
    <Table basic="very">
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            Salaris schaal {props.indicatie.situatie.schaal}.
            {props.indicatie.situatie.trede}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(props.indicatie.loon.salaris)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>IKB</Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(props.indicatie.loon.betalingIkb)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Premie ABP Pensioen/NP</Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(props.indicatie.loon.pensioenpremie)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Premie ABP AP</Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(props.indicatie.loon.pensioenpremieAOP)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Loonheffing</Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(props.indicatie.loon.loonheffing)}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
    <Table basic="very">
      <Table.Body>
        <Table.Row>
          <Table.Cell>Huispersoneel</Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(props.indicatie.vergoedingen.huispersoneel)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Passieve representatie</Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(
              props.indicatie.vergoedingen.passieveRepresentatie
            )}
          </Table.Cell>
        </Table.Row>
        {props.indicatie.vergoedingen.passieveRepresentatiePartner > 0 ? (
          <Table.Row>
            <Table.Cell>Passieve representatie partner</Table.Cell>
            <Table.Cell textAlign="right">
              {formatter.format(
                props.indicatie.vergoedingen.passieveRepresentatiePartner
              )}
            </Table.Cell>
          </Table.Row>
        ) : (
          ""
        )}
        <Table.Row>
          <Table.Cell>Transport</Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(props.indicatie.vergoedingen.transport)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Standplaats</Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(props.indicatie.vergoedingen.standplaats)}
          </Table.Cell>
        </Table.Row>
        {props.indicatie.vergoedingen.standplaatsPartner > 0 ? (
          <Table.Row>
            <Table.Cell>Standplaats partner</Table.Cell>
            <Table.Cell textAlign="right">
              {formatter.format(
                props.indicatie.vergoedingen.standplaatsPartner
              )}
            </Table.Cell>
          </Table.Row>
        ) : (
          ""
        )}
        {props.indicatie.vergoedingen.standplaatsKinddelen > 0 ? (
          <Table.Row>
            <Table.Cell>Standplaats kinddelen</Table.Cell>
            <Table.Cell textAlign="right">
              {formatter.format(
                props.indicatie.vergoedingen.standplaatsKinddelen
              )}
            </Table.Cell>
          </Table.Row>
        ) : (
          ""
        )}
        {props.indicatie.vergoedingen.oudedagsvoorzieningPartner > 0 ? (
          <Table.Row>
            <Table.Cell>Oudedagvoorziening partner</Table.Cell>
            <Table.Cell textAlign="right">
              {formatter.format(
                props.indicatie.vergoedingen.oudedagsvoorzieningPartner
              )}
            </Table.Cell>
          </Table.Row>
        ) : (
          ""
        )}
        {props.indicatie.vergoedingen.koopkrachtcorrectieNettoSalaris > 0 ? (
          <Table.Row>
            <Table.Cell>Koopkrachtcorrectie netto salaris</Table.Cell>
            <Table.Cell textAlign="right">
              {formatter.format(
                props.indicatie.vergoedingen.koopkrachtcorrectieNettoSalaris
              )}
            </Table.Cell>
          </Table.Row>
        ) : (
          ""
        )}
      </Table.Body>
    </Table>
    <Table basic="very">
      <Table.Body>
        <Table.Row>
          <Table.Cell>Huisvesting</Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(props.indicatie.inhoudingen.huisvesting)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Bijkomende kosten huisvesting</Table.Cell>
          <Table.Cell textAlign="right">
            {formatter.format(
              props.indicatie.inhoudingen.bijkomendeKostenHuisvesting
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>
);

export function IndicatieSidebar(props: {
  readonly indicatie: Indicatie | undefined;
  readonly removeSituatie: (id: string) => string;
}) {
  if (!props.indicatie) return <div />;
  return (
    <div className="Sidebar">
      <Header as="h1">{props.indicatie.standplaats.naam}</Header>

      <div className="Indicatie-property-container">
        <div className="Indicatie-property">
          <label>Zone</label>
          {props.indicatie.standplaats.zoneIndeling}
        </div>
        <div className="Indicatie-property">
          <label>Cat.</label>
          {props.indicatie.standplaats.categorieIndeling}
        </div>
        <div className="Indicatie-property">
          <label>KKC</label>
          {(props.indicatie.standplaats.kkc || NaN).toLocaleString('nl-NL')}
        </div>
      </div>
      <TableExampleCollapsing indicatie={props.indicatie} />
      <div className="Total-property-container right">
        <div className="Total-property">
          <label>Netto uitbetaling</label>
          {formatter.format(sumIndicatie(props.indicatie))}<br />&nbsp;
        </div>
      </div>
      <div className="Indicatie-delete">
        <Button
          fluid
          color="red"
          onClick={() =>
            props.removeSituatie(
              props.indicatie ? props.indicatie.situatie.id || "" : ""
            )
          }
        >
          Verwijderen
        </Button>
      </div>
    </div>
  );
}
