import React, { useEffect, useState } from "react";
import { Container, Grid, Header, Icon, Sidebar } from "semantic-ui-react";

import { Indicatie } from "./components/Indicatie/Indicatie";
import { IndicatieSidebar } from "./components/Indicatie/IndicatieSidebar";
import { SituatieForm } from "./components/Situatie";
import { IndicatiesContainer } from "./components/SortableBoard";
import { Situatie } from "./lib/acru";

import "./App.css";
import "./assets/fomantic/dist/semantic.css";

function App() {
  const [situaties, setSituaties] = useState<Situatie[]>(JSON.parse(localStorage.getItem('situaties') || "[]")); // eslint-disable-line functional/prefer-readonly-type
  const [activeIndicatie, setActiveIndicatie] = useState<Indicatie | undefined>();

  useEffect(() => {
    localStorage.setItem('situaties', JSON.stringify(situaties));
  }, [situaties]);

  function addSituatie(situatie: Situatie): readonly Situatie[] {
    const updatedSituaties = [...situaties, situatie];
    setSituaties(updatedSituaties);
    return updatedSituaties;
  }

  function removeSituatie(id: string) {
    setSituaties(situaties.filter((a) => a.id !== id));
    setActiveIndicatie(undefined);
    return id;
  }

  function showIndicatieSidebar(indicatie: Indicatie) {
    setActiveIndicatie(indicatie);
    return indicatie;
  }


  return (
    <div style={{ height: "100vh" }}>
      <Sidebar.Pushable className="App" style={{ overflowY: "scroll" }}>
        <Sidebar.Pusher dimmed={activeIndicatie !== undefined}>
          <div >
            <div className="App-control">
              <div className="canvas">
                <Container>
                  <header className="App-header">
                    <Header as="h1">Standplaatsindicatie</Header>
                  </header>
                  <br />
                  <Header className="App-subheader" as="h3">
                    Voeg een situatie toe
                  </Header>
                  <SituatieForm addSituatie={addSituatie} />
                </Container>
              </div>
            </div>
            <IndicatiesContainer
              showIndicatieSidebar={showIndicatieSidebar}
              situaties={situaties}
              setSituaties={setSituaties}
            />
            <div className="App-footer">Laatste update: 26-05-2024 <br /> <a href="mailto: contact@concepts.digital"><Icon name='mail' /> contact</a></div>
          </div>
          <br /><br />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <Grid.Column>
        <Sidebar
          animation="overlay"
          icon="labeled"
          onHide={() => setActiveIndicatie(undefined)}
          visible={activeIndicatie !== undefined}
          width="very wide"
          direction="right"
        >
          <IndicatieSidebar indicatie={activeIndicatie} removeSituatie={removeSituatie} />
        </Sidebar>
      </Grid.Column>

    </div>
  );
}

export default App;
