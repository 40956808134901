
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { countryCodeEmoji } from "country-code-emoji";
import Twemoji from "react-twemoji";
import { Card, Grid, Header, Icon, Popup, Table } from "semantic-ui-react";
import twemoji from "twemoji";

import { acru, landen } from "../../lib/acru";
import {
  inhoudingBijkomendeKostenHuisvesting,
  inhoudingHuisvesting,
  koopkrachtcorrectieNettoSalaris,
  oudedagsvoorzieningPartner,
  Situatie,
  Standplaats,
  standplaatsvergoeding,
  standplaatsvergoedingKinddelen,
  standplaatsvergoedingPartner,
  transportvergoeding,
  vergoedingHuispersoneel,
  vergoedingPassieveRepresentatie,
  vergoedingPassieveRepresentatiePartner,
} from "../../lib/acru";
import {
  ikb,
  loonheffing,
  premieABPPensioenAP,
  premieABPPensioenNP,
  salaristabel,
} from "../../lib/brutonetto";

export type Indicatie = {
  readonly id: string;
  readonly situatie: Situatie;
  readonly standplaats: Standplaats;
  readonly loon: Loon;
  readonly vergoedingen: Vergoedingen;
  readonly inhoudingen: Inhoudingen;
};

type Loon = {
  readonly salaris: number;
  readonly betalingIkb: number;
  readonly pensioenpremie: number;
  readonly pensioenpremieAOP: number;
  readonly loonheffing: number;
};

type Vergoedingen = {
  readonly huispersoneel: number;
  readonly passieveRepresentatie: number;
  readonly passieveRepresentatiePartner: number;
  readonly transport: number;
  readonly standplaats: number;
  readonly standplaatsPartner: number;
  readonly standplaatsKinddelen: number;
  readonly oudedagsvoorzieningPartner: number;
  readonly koopkrachtcorrectieNettoSalaris: number;
};

type Inhoudingen = {
  readonly huisvesting: number;
  readonly bijkomendeKostenHuisvesting: number;
};

const formatter = new Intl.NumberFormat("nl-NL", {
  style: "currency",
  currency: "EUR",
});

export function sumIndicatie(indicatie: Indicatie): number {
  const loon: number = Object.values(indicatie.loon).reduce((a, b) => a + b, 0);
  const vergoedingen: number = Object.values(indicatie.vergoedingen).reduce(
    (a, b) => a + b,
    0
  );
  const inhoudingen: number = Object.values(indicatie.inhoudingen).reduce(
    (a, b) => a + b,
    0
  );
  return loon + vergoedingen + inhoudingen;
}

export function createIndicatie(situatie: Situatie): Indicatie {
  const salaris = salaristabel[situatie.schaal][situatie.trede];
  const pensioenpremie = premieABPPensioenNP(salaris);
  const betalingIkb = ikb(salaris);
  const pensioenpremieAOP = premieABPPensioenAP(salaris);
  const indicatie: Indicatie = {
    id: situatie.id || "",
    standplaats: situatie.standplaats,
    situatie,
    loon: {
      salaris,
      betalingIkb,
      pensioenpremie,
      pensioenpremieAOP,
      loonheffing: loonheffing(
        salaris + betalingIkb + pensioenpremie + pensioenpremieAOP
      ),
    },
    vergoedingen: {
      huispersoneel: vergoedingHuispersoneel(situatie),
      passieveRepresentatie: vergoedingPassieveRepresentatie(situatie),
      passieveRepresentatiePartner:
        vergoedingPassieveRepresentatiePartner(situatie),
      transport: transportvergoeding(situatie),
      standplaats: standplaatsvergoeding(situatie),
      standplaatsPartner: standplaatsvergoedingPartner(situatie),
      standplaatsKinddelen: standplaatsvergoedingKinddelen(situatie),
      oudedagsvoorzieningPartner: oudedagsvoorzieningPartner(situatie),
      koopkrachtcorrectieNettoSalaris: koopkrachtcorrectieNettoSalaris(
        situatie,
        salaris +
        pensioenpremie +
        pensioenpremieAOP +
        loonheffing(salaris + pensioenpremie + pensioenpremieAOP)
      ),
    },
    inhoudingen: {
      huisvesting: inhoudingHuisvesting(
        situatie,
        salaris +
        pensioenpremie +
        pensioenpremieAOP +
        loonheffing(salaris + pensioenpremie + pensioenpremieAOP)
      ),
      bijkomendeKostenHuisvesting: inhoudingBijkomendeKostenHuisvesting(
        situatie,
        salaris +
        pensioenpremie +
        pensioenpremieAOP +
        loonheffing(salaris + pensioenpremie + pensioenpremieAOP)
      ),
    },
  };
  return indicatie;
}

export function Indicatie(props: { readonly situatie: Situatie; readonly showIndicatieSidebar: (indicatie: Indicatie) => Indicatie; }) {
  const indicatie = createIndicatie(props.situatie);
  const flagEmojiSrc = "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/" +
    twemoji.convert.toCodePoint(
      countryCodeEmoji(acru.standplaatsen[indicatie.standplaats.naam].land)
    ) + ".svg";

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.situatie.id || "" });

  const style = {
    zIndex: isDragging ? 1 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const partner = landen[indicatie.standplaats.land].partner;


  const stylePopup = {
    borderRadius: '10px',
    backgroundColor: '#092C4C',
  }


  return (
    <div className="draggable-card-container" ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Card onClick={() => { props.showIndicatieSidebar(indicatie); }}>
        <Card.Content>
          <Grid>
            <Grid.Row verticalAlign='middle' >
              <Grid.Column width={3}>
                <Header as="h4">
                  <Twemoji options={{ className: "twemoji" }}>
                    <span title={landen[indicatie.standplaats.land].naam}>
                      <img className="twemoji" src={flagEmojiSrc} />
                    </span>
                  </Twemoji>
                </Header>
              </Grid.Column>
              <Grid.Column width={13}>
                <Header as="h4" className='overflow'>{indicatie.standplaats.naam}<br />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <br />
            <Grid.Row verticalAlign='top' className='carddetails'>
              <Grid.Column width={11}>
                <Card.Header as="h4"><small className='subheader'>Netto uitbetaling</small>{formatter.format(sumIndicatie(indicatie))}</Card.Header>
              </Grid.Column>
              <Grid.Column width={5}>
                {partner ?
                  <Card.Header as="h4">
                    <small className='subheader'>Partner<br />
                      {partner.huwelijkMV === 'geaccepteerd' || partner.huwelijkMV === 'gedoogd' ? <Popup
                        trigger={<Icon name='venus mars' />}
                        inverted
                        wide
                        position='top center'
                        style={stylePopup}
                      >
                        <span className='header'>Verblijfstatus M/V</span>
                        <Table basic="very" inverted>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>
                                Huwelijk:
                              </Table.Cell>
                              <Table.Cell>
                                {partner.huwelijkMV}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                Geregistreerd partnerschap:
                              </Table.Cell>
                              <Table.Cell>
                                {partner.geregistreerdPartnerschapMV}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                Savenlevingsovereenkomst:
                              </Table.Cell>
                              <Table.Cell>
                                {partner.samenlevingsovereenkomstMV}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Popup> : ''}&nbsp;
                      {partner.huwelijkMMVV === 'geaccepteerd' || partner.huwelijkMMVV === 'gedoogd' ? <Popup
                        trigger={<Icon name='mars double' />}
                        inverted
                        wide
                        position='top center'
                        style={stylePopup}
                      >
                        <span className='header'>Verblijfstatus M/M of V/V</span>
                        <Table basic="very" inverted>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>
                                Huwelijk:
                              </Table.Cell>
                              <Table.Cell>
                                {partner.huwelijkMMVV}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                Geregistreerd partnerschap:
                              </Table.Cell>
                              <Table.Cell>
                                {partner.geregistreerdPartnerschapMMVV}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                Savenlevingsovereenkomst:
                              </Table.Cell>
                              <Table.Cell>
                                {partner.samenlevingsovereenkomstMMVV}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Popup> : ''}&nbsp;
                      {partner.werk ? <Popup
                        trigger={<Icon name='briefcase' />}
                        inverted
                        wide
                        header={'Werk'}
                        content={'Met dit land is er een ' + partner.werk}
                        position='top center'
                        style={stylePopup}
                      /> : ''}&nbsp;
                    </small>
                  </Card.Header>

                  : ''}

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    </div >
  );
}
