import { countryCodeEmoji } from "country-code-emoji";
import React, { FormEvent, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Dropdown,
  DropdownItemProps,
  Form,
  Grid,
  Input,
} from "semantic-ui-react";
import twemoji from "twemoji";

import { acru, landen, Situatie } from "../lib/acru";

import { createIndicatie } from "./Indicatie/Indicatie";

import "twemoji/dist/twemoji";


function validSituatie(situatie: Situatie) {
  try {
    createIndicatie(situatie);
    return true;
  } catch (e) {
    return false;
  }
}

const countryOptions = Object.keys(acru.standplaatsen).map((key) => {
  return {
    key: key,
    value: key,
    image: {
      className: "flag",
      src:
        "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/" +
        twemoji.convert.toCodePoint(
          countryCodeEmoji(acru.standplaatsen[key].land)
        ) +
        ".svg",
    },
    text: key,
    description: acru.standplaatsen[key].posten.join("-"),
  };
});

const searchFunction = (
  options: readonly DropdownItemProps[],
  value: string
) => {
  return options.filter((element: DropdownItemProps) => {
    return (
      (element.description
        ? element.description
          ?.toString()
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1
        : false) ||
      (element.text
        ? element.text?.toString().toLowerCase().indexOf(value.toLowerCase()) >
        -1
        : false) ||
      (element.text
        ? landen[acru.standplaatsen[element.text?.toString()].land].naam.toLowerCase().indexOf(value.toLowerCase()) >
        -1
        : false)
    );
  });
};

export function SituatieForm(props: {
  readonly addSituatie: (situatie: Situatie) => readonly Situatie[];
}) {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form as HTMLFormElement);
    const formJson = Object.fromEntries(formData.entries());
    const situatie = {
      id: Date.now() * Math.random() + "",
      partner: formJson.partner === "",
      gescheidenHuishouding: formJson.gescheidenHuishouding === "",
      kinderen0tm11: Number.parseInt(formJson.kinderen0tm11.toString()) || 0,
      kinderen12plus: Number.parseInt(formJson.kinderen12plus.toString()) || 0,
      standplaats: acru.standplaatsen[selectedStandplaats],
      schaal: Number.parseInt(formJson.schaal.toString()) || 0,
      trede: Number.parseInt(formJson.trede.toString()) || 0,
      CdP: formJson.CdP === "",
      ingerichteDienstwoning: formJson.ingerichteDienstwoning === "",
      dienstauto: formJson.dienstauto === "",
    }

    if (validSituatie(situatie)) {
      props.addSituatie(situatie);
    } else {
      alert('Ongeldige situatie')
    }

  };

  const [selectedStandplaats, setSelectedStandplaats] = useState("");
  const [checkedPartner, setCheckedPartner] = useState(false);
  const [checkedGescheidenHuishouding, setCheckedGescheidenHuishouding] = useState(false);

  return (
    <Form onSubmit={handleSubmit} size="large" autoComplete="off">
      <Grid relaxed stackable>
        <Grid.Row divided>
          <Grid.Column width={6}>
            <Form.Field
              control={Checkbox}
              name="partner"
              label={<label>Partner</label>}
              onChange={(e: React.ChangeEvent<HTMLInputElement>, data: CheckboxProps) => {
                setCheckedPartner(data.checked || false);
                if (!data.checked) {
                  setCheckedGescheidenHuishouding(false)
                }
              }}
            />
            <Form.Field
              name="gescheidenHuishouding"
              control={Checkbox}
              label={<label>Gescheiden gezinssituatie</label>}
              disabled={!checkedPartner}
              onChange={(e: React.ChangeEvent<HTMLInputElement>, data: CheckboxProps) => {
                setCheckedGescheidenHuishouding(data.checked || false);
              }}
              checked={checkedGescheidenHuishouding}
            />
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Field
                    name="kinderen0tm11"
                    id="form-input-control-kinderen0tm11"
                    control={Input}
                    label="Kinderen 0-11"
                    placeholder="0"
                    type="number"
                    min="0"
                    max="10"
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field
                    name="kinderen12plus"
                    id="form-input-control-kinderen12plus"
                    control={Input}
                    label="Kinderen 12+"
                    placeholder="0"
                    type="number"
                    min="0"
                    max="10"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={10}>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={10}>
                  <div className="field">
                    <label>Standplaats</label>
                    <Dropdown
                      name="standplaatsNaam"
                      placeholder="Selecteer standplaats"
                      id="form-input-control-standplaats"
                      fluid
                      search={searchFunction}
                      selection
                      required
                      value={selectedStandplaats}
                      options={countryOptions}
                      onChange={(e, { value }) => {
                        setTimeout(() => {
                          (
                            document.getElementsByClassName(
                              "search"
                            )[1] as HTMLElement
                          ).blur();
                        }, 0);
                        setSelectedStandplaats((value || "").toString());
                      }}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Form.Field
                          name="schaal"
                          id="form-input-control-schaal"
                          control={Input}
                          label="Schaal"
                          defaultValue={11}
                          type="number"
                          min="6"
                          max="19"
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Field
                          name="trede"
                          id="form-input-control-trede"
                          control={Input}
                          label="Trede"
                          defaultValue={0}
                          type="number"
                          min="0"
                          max="10"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid verticalAlign="bottom" stackable>
              <Grid.Row>
                <Grid.Column width={11}>
                  <Grid verticalAlign="bottom" stackable>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Form.Field
                          name="CdP"
                          control={Checkbox}
                          label={<label>CdP of plv CdP</label>}
                        />
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <Form.Field
                          name="dienstauto"
                          control={Checkbox}
                          label={
                            <label>
                              Dienstauto
                            </label>
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16}>

                        <Form.Field
                          name="ingerichteDienstwoning"
                          control={Checkbox}
                          label={
                            <label>
                              Voor representatieve doeleinden ingerichte dienstwoning
                            </label>
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={5} textAlign="right">
                  <Button type="submit" primary disabled={!selectedStandplaats}>
                    Toevoegen
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}
