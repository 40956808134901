import {
    closestCenter,
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
} from '@dnd-kit/sortable';
import { Dispatch, SetStateAction, useEffect } from "react";
import { Card, Container, Header } from "semantic-ui-react";

import { Situatie } from "../lib/acru";

import { Indicatie } from "./Indicatie/Indicatie";

function IndicatieCardContainer(props: {
    readonly situaties: Situatie[];// eslint-disable-line functional/prefer-readonly-type
    readonly showIndicatieSidebar: (indicatie: Indicatie) => Indicatie;
    readonly setSituaties: Dispatch<SetStateAction<Situatie[]>>// eslint-disable-line functional/prefer-readonly-type
}) {
    const sensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 8 } })
    );
    useEffect(() => {
        localStorage.setItem('situaties', JSON.stringify(props.situaties));
    }, [props.situaties]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <Card.Group itemsPerRow={3}>
                <SortableContext
                    items={props.situaties}
                    strategy={rectSortingStrategy}
                >
                    {props.situaties.map(situatie => {
                        return (
                            <Indicatie
                                situatie={situatie}
                                key={situatie.id}
                                showIndicatieSidebar={props.showIndicatieSidebar}
                            />
                        );
                    })}
                </SortableContext>
            </Card.Group>
        </DndContext>
    );

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            props.setSituaties((situaties) => {
                const oldIndex = situaties.findIndex(situatie => situatie.id === active.id)
                const newIndex = situaties.findIndex(situatie => situatie.id === over.id)
                return arrayMove(situaties, oldIndex, newIndex);
            });
        }
    }
}

export function IndicatiesContainer(props: {
    readonly showIndicatieSidebar: (indicatie: Indicatie) => Indicatie;
    readonly situaties: Situatie[]; // eslint-disable-line functional/prefer-readonly-type
    readonly setSituaties: Dispatch<SetStateAction<Situatie[]>>; // eslint-disable-line functional/prefer-readonly-type
}) {
    return (
        <Container className="App-subheader">
            <Header as="h3">Indicaties</Header>
            {props.situaties.length === 0 ? (
                <Header as="h3" className="no-indications">
                    Voeg een situatie toe om te beginnen
                </Header>
            ) : (
                <IndicatieCardContainer
                    situaties={props.situaties}
                    setSituaties={props.setSituaties}
                    showIndicatieSidebar={props.showIndicatieSidebar}
                />

            )}
        </Container>)
}
